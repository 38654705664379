import React, { createContext, useState } from 'react';
import Home from './components/home';
import Footer from './components/footer';
import logo from './asset/logo.png';

import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PassagersInfo from './components/passager-infos';
import Recap from './components/recap';
import Confirmation from './components/confirmation';
import PayForm from './components/payment';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    home_button: {
      fontFamily:'Inter',
      fontSize:16,
      fontWeight: 600,
    },
    h1: {
      fontFamily:'Inter',
      fontSize:28,
      fontWeight: 500,
      textDecoration:'underline',
      color: '#FF3C83',
      marginTop:'15px'
    },
    h2: {
      fontFamily: 'Inter',
      fontWeight:500,
      fontSize:22
    },
    h3: {
      fontFamily: 'Inter',
      fontWeight:500,
      fontSize:20
    },
    h4: {
      fontFamily:'Inter',
      fontWeight:600,
      fontSize:18
    },
    h5: {
      fontFamily:'Inter',
      fontWeight:500,
      fontSize:18
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontSize:16,
      fontWeight:600
    },
    subtitle2: {
      fontFamily: 'Inter',
      fontSize:14,
      fontWeight:400
    },
    body1: {
      fontFamily: 'Inter',
      fontSize:14,
      fontWeight:400
    },
    info:{
      fontFamily:'Inter',
      fontSize:12,
      fontWeight:400
    },
  },
  components: {
    MuiButton: {
      styleOverrides:{
        root: {
          borderRadius:'2px',
          fontWeight:700
        }
      }
    },
    MuiTableRow:{styleOverrides:{ root:{
      border:'2px solid rgba(0,0,0,0.5)',
    }}},
  },
  palette: {
    primary: {
      main: '#3F88C5',
      light: '#A5DDFF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D9D9D9',
      contrastText: '#000',
    },
    rose: {
      main: '#FF3C83',
      contrastText: '#fff',
    },
    yellow: {
      main: '#EBE600',
      contrastText: '#000',
    },
    red: {
      main: '#FF0000'
    },
    vert: {
      main: '#4DA167',
      light: '#91EEAB',
      contrastText: '#000'
    },
    black:{
      main: '#000'
    },
    error:{
      main: '#590925'
    }
  },
});

const router = createBrowserRouter([
  {path: '/', element: <Home />},
  {path: '/passagers', element: <PassagersInfo />},
  {path: '/recapitulatif', element: <Recap />},
  {path: '/paiement', element: <PayForm/>},
  {path: '/confirmation', element: <Confirmation />},
  {path: '*', element: <Home />},
])

export const Context = createContext()

function App() {
  const [isAR, setAR] = useState(true)
  const [isShopping, setShopping] = useState(false)
  const [rotations, setRotations] = useState([])
  const [passagers, setPassagers] = useState([])
  const [mail, setmail]=useState('')
  const [reservation, setReservation] = useState()
  
  return (
    <Context.Provider value={{isAR,setAR, isShopping,setShopping, rotations,setRotations, passagers,setPassagers, mail,setmail, reservation,setReservation}} >
      <ThemeProvider theme={theme}>
        <div style={{height:'100%', display:'flex', flexDirection:'column', fontFamily:'Inter'}}>
          <img src={logo} alt='logo' height='80px' style={{marginLeft:'15px', objectFit:'contain', alignSelf:'start'}} />
          <RouterProvider router={router}/> 
          <Footer/>
        </div>
      </ThemeProvider>
    </Context.Provider>
  );
}

export default App;