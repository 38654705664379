import { Backdrop, Box, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import moment from 'moment';
import { API } from "../asset/conf";
import { useNavigate } from "react-router-dom";
import { Context } from "../App";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PayElement from "./pay-element";

const stripePromise = loadStripe('pk_live_51Pif3DAjnE4DEvsKOzJ1t8c9TF2KfdZxM6nOEvNYNzGu57X88g5h4taqXw6N6y3UlZc1pNYKLSFERRgMItIZj3AE00AMT15gdK');

export default function PayForm(props) {
    const navigate = useNavigate()
    const {isAR, rotations, passagers} = useContext(Context)
    const [load, setLoad] = useState(false)
    const [promos, setPromos] = useState([])
    const [code, setCode] = useState('')
    const [price, setPrice] = useState(1)
    const [total, setTotal] = useState(0)
    const [secret, setSecret] = useState('')
    const options = {
        // mode: 'payment',
        // amount: Number(price.toFixed(2))*100,
        // currency: 'eur',
        clientSecret: secret,
        // Personnalisez l'apparence si nécessaire
        // appearance: {/*...*/},
    };

    useEffect(()=>{
        async function get_promo() {
            fetch(API.concat(`/reservation/promos/${JSON.stringify({$and: [{start_date: {$lte: new Date(moment().format('yyyy-MM-DD'))}}, { end_date: {$gte: new Date(moment().format('yyyy-MM-DD'))}}]})}`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }}).then(async response=>{
                if (response.status === 200) {
                    const json = await response.json()
                    setPromos(json)
                }
            })
        }

        function getPrice() {
            fetch(API.concat(`/reservation/tarifs`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }}).then(async response=>{
                if (response.status === 200) {
                    const json = await response.json()
                    const tt = passagers.filter(psg=>psg.categorie!=='nourrisson')
                    .reduce((tt, current)=>tt+
                    json.find(tf=>tf.isA_R===isAR&&tf.is_journee===!rotations.some(rt=>!rt.is_journee)&&current.categorie?.toLowerCase().includes(tf.categorie))?.prix??0,
                    0)
                    if (tt>0) {
                        setTotal(tt); setPrice(tt)
                    } else navigate('/')
                }
                setLoad(false)
            })
        }

        if (rotations.length<1||passagers.length<1) {
            navigate('/')
        } else{
            get_promo(); getPrice()
        }
    },[isAR, navigate,passagers, rotations])

    useEffect(()=>{
        setLoad(true)
        fetch(API.concat('/stripe/payment'), {method:'POST', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
            }, body:JSON.stringify({amount: Number(price.toFixed(2))*100})
        }).then(async response=>{
            if (response.status===200) {
                const json = await response.json();
                setSecret(json.client_secret)
            }setLoad(false)
        })
    }, [price])
    
    return(
            <Box display='flex' flex={1} flexDirection='column' paddingInline={4}>
                <Typography variant="subtitle1" marginTop={5} marginBottom={2}>Code promo</Typography>
                <TextField size="small" value={code} sx={{width:'20%', minWidth:'200px', marginBottom:1}} onChange={e=>{
                    setCode(e.target.value); 
                    const promo = promos.find(prom=> prom.code===e.target.value)
                    if (promo&&promo.reduction>0) {
                        setPrice(total-(promo.is_in_percent? Number((total*promo.reduction/100).toFixed(2)) : promo.reduction))
                    } else if(price!==total) setPrice(total)
                }}/>
                <Typography>Total: {price.toFixed(2)}€</Typography>

                {!load&&secret!==''&&<Elements stripe={stripePromise} options={options}>
                    <PayElement price={price} promo={code}/>
                </Elements>}

                <Backdrop open={load}><CircularProgress/></Backdrop>
            </Box>
    )
}