import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, IconButton, ButtonGroup, Typography, Button, CircularProgress, ToggleButtonGroup, ToggleButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../App";
import moment from 'moment';
import 'moment/locale/fr';


export default function RotatDays(props) {
    moment.locale('fr')
    const [select, setSelect] = useState(moment(props.date).format('yyyy-MM-DD'))
    const [start_date, setStart] = useState('')
    const [load, setload] = useState(true)
    const [select_rotation, setrotation] = useState('')

    function get_rotations(date) {
        const rtn = props.rotations.filter(rtn=>{
            return ((rtn.date_depart&&moment.utc(rtn.date_depart??'').isSame(moment.utc(date), 'day'))||rtn.days?.includes(moment.utc(date).format('dddd')))&&
            (moment.utc(date).isSame(moment(),'day')?moment(rtn.heure_depart.join(':'), "HH:mm").add(-3,'hours').isAfter(moment(),'hours'):true)&&
            !(rtn.is_permanent&&props.rotations.find(rt=>rt.date_depart&&moment.utc(rt.date_depart??'').isSame(moment.utc(date), 'day')&&!rt.is_permanent&&rt.heure_depart.join(':')===rtn.heure_depart.join(':')))&&
            !rtn.canceled&&!rtn.is_clotured&&rtn.dispo_places>=props.psg_count
        })
        return rtn
    }

    useEffect(()=>{
        if (moment.utc(props.date).get('weekday')<6) {
            setStart(moment.utc(props.date).weekday(6).add(-7,'days').format('yyyy-MM-DD'))           
        } else setStart(moment.utc(props.date).format('yyyy-MM-DD'))
        setload(false)
    }, [props.date, props.rotations])

    useEffect(()=>{}, [start_date])

    return( load ?
        <CircularProgress/> :
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{marginTop:'30px'}}>
            <Box display='flex' justifyContent='center' alignItems='center' overflow='auto' >
                <IconButton 
                disabled={moment(start_date).isSameOrBefore(moment(), 'day')} 
                onClick={()=>setStart(old=> moment.utc(old).add(-7, 'days').format('yyyy-MM-DD'))}><ArrowBack/></IconButton>

                <ButtonGroup variant="text">{[0, 1, 2, 3, 4, 5, 6].map(index=> moment.utc(start_date).add(index, 'days').format('yyyy-MM-DD')).map(date =>
                    <Button key={date} onClick={()=> setSelect(old=>{if(date!==old){setrotation('')} return date})} 
                    style={{textTransform:'none', minWidth:'120px',backgroundColor: date===select ? theme.palette.primary.main :'transparent', color: date===select ? 'white': theme.palette.primary.main }}>
                        <Typography>{moment(date).format('ddd DD MMM')}<br/>à partir de<br/>
                        <span style={{fontWeight:'bold'}}>{moment.utc(date).isBefore(moment(), 'day')|| get_rotations(date).length<=0 ?'-': `${props.price}€`}</span></Typography>
                    </Button>
                )}</ButtonGroup>

                <IconButton onClick={()=>setStart(old=> moment.utc(old).add(7, 'days').format('yyyy-MM-DD'))}><ArrowForward/></IconButton>
            </Box>
            <Box minWidth='840px' display='flex' justifyContent='center' minHeight='150px' alignItems='center' sx={{backgroundColor:'primary.main'}}>
                {moment(select).isBefore(moment(), 'day')|| get_rotations(select).length<=0 ?
                <Typography>Aucune traversée pour ce jour</Typography> :
                <Box display='flex' flex={1} padding={2} flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <ToggleButtonGroup
                    value={select_rotation}
                    exclusive
                    orientation="vertical"
                    onChange={(e, selected)=>{setrotation(selected??''); props.setrotation(selected??''); props.setdate(selected?select:props.date)}}
                    sx={{border:0}}
                    aria-label="traversée"
                    >
                        {get_rotations(select).map(rotation=>
                        <ToggleButton key={rotation._id} value={rotation._id} sx={{border:0}}>
                            <Box display='flex' alignItems='center' padding={1} borderRadius='2px' sx={{backgroundColor:select_rotation===rotation._id ? 'primary.contrastText' : 'primary.light'}}>
                                <Typography variant="subtitle1" marginRight='10px'>{moment(rotation.heure_depart.join(':'), 'HH:mm').format('HH:mm')}</Typography>
                                <Typography textTransform='uppercase'>{rotation.from.nom}</Typography>
                                <ArrowForward sx={{marginX:'60px'}}/>
                                <Typography variant="subtitle1" marginRight='10px'>{moment(rotation.heure_depart.join(':'), 'HH:mm').add(rotation.duree, 'minutes').format("HH:mm")}</Typography>
                                <Typography textTransform='uppercase'>{rotation.to.nom}</Typography>
                            </Box>
                        </ToggleButton>)}
                    </ToggleButtonGroup>
                    <Box padding={2} borderRadius='2px' sx={{backgroundColor:'primary.light'}}>
                        <Typography textAlign='center' variant="subtitle1">{props.price}€</Typography>
                        {props.a_r && <Typography>Prix total Aller/Retour</Typography>}
                    </Box>
                </Box>}
            </Box>
        </Box>
    )
}