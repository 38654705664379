import React, { useContext, useEffect } from "react";
import { Context } from "../App";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Confirmation() {
    const {reservation} = useContext(Context)
    const navigate = useNavigate()
    useEffect(()=>{
        if (!reservation) {
            navigate('/')
        }
    }, [reservation])

    return(
        <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
            <Paper elevation={5} sx={{padding:'20px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Typography variant="h3" align="center" marginBottom={5}>Félicitation!<br/>Votre réservation a bien été enregistrée sous la référence {reservation.num}</Typography>

                <Button href="caporosso.fr">Accueil Transport Express Caraïbes</Button>
            </Paper>
        </Box>
    )
}